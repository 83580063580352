#searcharea {
    -webkit-appearance: none;
    border: none;
    border-radius: 5px;
    resize: none;
    font-size: 16.6px;
    padding: 19px 20px;
    font-family: "Lato", sans-serif;
    margin: 0px;
    width: 338px;
    position: relative;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color:#9d9fa2;
    height: 58px;
    }
    #searchareapar{position: relative}
    #searchareapar:before{
        content: ".";
        position: absolute;
        right:10px;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        font-size: 0px;
        width:18px;
        height: 18px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('https://www.reliantrs.com/dev/wp-content/themes/reliant/images/searchiconx2.png');
        z-index: 2
    }
    
    #searchsubmit {
    margin: 0px;
    -webkit-appearance: none;
    font-size: 16px;
    line-height: 16px;
    color: white;
    text-transform: uppercase;
    background: #004f73;
    font-weight: 600;
    border-radius: 5px;
    display: inline-table;
    letter-spacing: 1.8px;
    text-align: center;
    padding: 22px 17px 21px 21px;
    box-sizing: border-box;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-family: "Lato",sans-serif;
    vertical-align: middle;
    cursor: pointer;
    width: 181px;
    }
    .search .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 14px;
    font-size: 16.6px;
    line-height: 59px;
    color: #9d9fa2;
    height: 58px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: "Lato", sans-serif;
    }
    .selectric-wrapper {
      position: relative;
      cursor: pointer;
    }
    
    .selectric-responsive {
      width: 100%;
    }
    
    .selectric {
      border: 1px solid #DDD;
      border-radius: 0px;
      background: #F8F8F8;
      position: relative;
      overflow: hidden;
    }
    
    .selectric .label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 38px 0 10px;
      font-size: 12px;
      line-height: 38px;
      color: #444;
      height: 38px;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    
    .selectric .button {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 38px;
      height: 38px;
      line-height: 38px;
      background-color: #F8f8f8;
      color: #BBB;
      text-align: center;
      font: 0/0 a;
      *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
    }
    
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none;
    }
    
    .selectric-focus .selectric {
      border-color: #aaaaaa;
    }
    
    .selectric-hover .selectric {
      border-color: #c4c4c4;
    }
    
    .selectric-hover .selectric .button {
      color: #a2a2a2;
    }
    
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a2a2;
    }
    
    .selectric-open {
      z-index: 9999;
    }
    
    .selectric-open .selectric {
      border-color: #c4c4c4;
    }
    
    .selectric-open .selectric-items {
      display: block;
    }
    
    .selectric-disabled {
      filter: alpha(opacity=50);
      opacity: 0.5;
      cursor: default;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    .selectric-input {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      margin: 0 !important;
      padding: 0 !important;
      width: 1px !important;
      height: 1px !important;
      outline: none !important;
      border: none !important;
      *font: 0/0 a !important;
      background: none !important;
    }
    
    .selectric-temp-show {
      position: absolute !important;
      visibility: hidden !important;
      display: block !important;
    }
    
    /* Items box */
    .selectric-items {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background: #F8F8F8;
      border: 1px solid #c4c4c4;
      z-index: -1;
      box-shadow: 0 0 10px -6px;
    }
    
    .selectric-items .selectric-scroll {
      height: 100%;
      overflow: auto;
    }
    
    .selectric-above .selectric-items {
      top: auto;
      bottom: 100%;
    }
    
    .selectric-items ul, .selectric-items li {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 12px;
      line-height: 20px;
      min-height: 20px;
    }
    
    .selectric-items li {
      display: block;
      padding: 10px;
      color: #666;
      cursor: pointer;
    }
    
    .selectric-items li.selected {
      background: #E0E0E0;
      color: #444;
    }
    
    .selectric-items li.highlighted {
      background: #D0D0D0;
      color: #444;
    }
    
    .selectric-items li:hover {
      background: #D5D5D5;
      color: #444;
    }
    
    .selectric-items .disabled {
      filter: alpha(opacity=50);
      opacity: 0.5;
      cursor: default !important;
      background: none !important;
      color: #666 !important;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    
    .selectric-items .selectric-group .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background: none;
      color: #444;
    }
    
    .selectric-items .selectric-group.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }
    
    .selectric-items .selectric-group li {
      padding-left: 25px;
    }
    
    
    .selectric-hide-select select {
        border: none;
        border-radius: 0;
        resize: none;
        font-size: 16.6px;
        padding: 19px 20px;
        font-family: "Lato", sans-serif;
        margin: 0px;
        width: 170px;
        position: relative;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        color: #9d9fa2;
        height: 58px;
        border-left: 1px solid #d4d9de;
    }
    
    .search form {
        display: flex;
        align-items: center;
        padding-top: 35px;
    }
    
    .selectric-hide-select select option {
        padding: 18px 10px;
    }

    .inner_banner {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 420px;
    }
    /* .inner_banner {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 420px;
    } */
    #breadcrumb{font-family: "Lato", sans-serif;color: #4f5660;
        margin-top: 5px;
    }
    #breadcrumb a {
    color: #4f5660;
    border-bottom: 2px solid #878b91;
    }
    #breadcrumb span {
    margin: 0 3px;
    }
    
    #breadcrumb .container-fluid {
        padding: .3rem 0;
    }
    .property_section .btn.btn-primary {
      width: 100%;
      margin-top: 15px;
      font-size: 1.1rem;
      font-weight: 600;
      padding: 20px 16px;
      background: #004f73;
    }
    .property_section button.btn.btn-light {
      background: #888;
      color: #fff;
      width: 100%;
      margin-top: 15px;
      font-size: 1.1rem;
      font-weight: 600;
      padding: 20px 16px;
    }


    .property_section .container-fluid {
      padding: 1.5rem 0rem 3rem;
    }
    .pname {
      font-size: 48px;
      color: #004f73;
      margin-bottom: 25px;
      margin-top: 15px;
    }
    .paddress {
      font-size: 16px;
      color: #004f73;
      margin-bottom: 20px;
      line-height: 26px;
      font-weight: 600;
    }
    
    .pdesc p{font-size: 16px;line-height: 26px;color:#4f5660;margin-bottom: 26px;font-weight: 500; margin-bottom:15px}
    .pdesc p strong {
      font-weight: 700;
    }
    
    .pcontact p{font-size: 16px;line-height: 26px;color:#4f5660;margin-bottom: 26px;font-weight: 500; margin-bottom:15px}
    .pcontact p strong {
      font-weight: 700;
    }
    #propertyintro p{margin-bottom: 12px}
    .pdesc li {
      padding-left: 42px;
      position: relative;
      font-size: 16px;
      line-height: 26px;
      color: #4f5660;
      font-weight: 500;
    }
    .pdesc li::before {
      content: "·";
      color: rgb(79, 86, 96);
      font-size: 22px;
      font-weight: 700;
      position: absolute;
      left: 20px;
    }
    .pctitle {
      color: #004f73;
      font-size: 24px;
      margin-bottom: 16px;
      margin-top: 30px;
    }
    .pcontact a {
      text-decoration: none;
      color: #4f5660;
    }
    
    .pdesc ul {
      list-style: none;
      padding: 0;
    }
    
    .paddress p {
        text-align: left;
    }
    @media (max-width: 1225px) and (min-width: 959px) { 
      .property_section .container-fluid {
        width: 900px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 959px){
      .property_section .container-fluid {
        width: 730px;
      } 
    }
    @media (max-width: 768px) {
      #breadcrumb .container-fluid {
        padding: 0.3rem 15px;
      }
      #breadcrumb a {
        font-size: 13px;
      }
      .property_section .container-fluid {
        width: 100%;
        padding: 20px 10px 10px;
      } 
      .property_section .container {
        max-width: 100%;
      }
      .pname {
        font-size: 29px;
        margin-bottom: 15px;
        margin-top: 5px;
        line-height: 33px;
      }
      .paddress {
        font-size: 15px;
        margin-bottom: 10px;
      }
      .pdesc p {
        font-size: 13px;
        line-height: 20px;
        text-align: left;
      }
      .pcontact p {
        font-size: 13px;
        line-height: 20px;
        text-align: left;
      }
      .property_section .slider-img-container img {
        width: 98%;
      }
    }