@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
$primary: #12618A;
$white: #FFFFFF;
$dark: #004F73;
$grey: #72828A;

#root{
    overflow-x: hidden !important;
}

.split-parent {
    overflow: hidden;
}

.navbar-collapse{
    &.collapsing{
        background-color: rgba(255, 255, 255, 0.50);
        padding: 14px;
        backdrop-filter: blur(5px);
        ul li a{
            color: #000;
        }
    }
    &.collapse{
        &.show{
            background-color: rgba(255, 255, 255, 0.50);
            padding: 14px;
            backdrop-filter: blur(5px);
            ul li a{
                color: #000;
            }
        }
    }
}

.split-child {
    display: inline-block;
}

.container-fluid{
    max-width: 1240px;
}
.navigation{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    .nav-link{
        font-size: 0.8rem;
    }
    &.inner{
        background: $primary;
        position: relative;
    }
}
.btn{
    font-size: 0.8rem;
    font-style: normal;
    line-height: 1rem; /* 100% */
    text-transform: uppercase;
    padding: 1rem 2rem;
    border: none !important;
    &.btn-primary{
        background: $primary;
        &:hover{
            background: $dark;
        }
    }
    &.btn-secondary{
        background: $white;
        color: #000;
        &:hover{
            background: $dark;
            color: #fff;
        }
    }
}
.form-control{
    border: none !important;
    box-shadow: none !important;
    background: rgba($color: $grey, $alpha: 0.05) !important;
    border-radius: 0.3125rem;
    font-size: 0.9rem;
    padding: 1rem;
    grid-column-start: 1;
    grid-column-end: 3;
}
body{
    background: #F9F9F9;
}
.hero{
    background: $primary;
    color: $white;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .hero_inner{
        min-height: 40rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 1rem;
        p{
            max-width: 45rem;
            color: $white;
        }
    }
    .video_wrapper{
        position: absolute;
        width: 100%;
        height: 110%;
        top: -5px;
        left: 0;
        z-index: -1;
        &::after{
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba($color: #000000, $alpha: 0.6);
        }
        video{
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }
}
.about{
    padding-top: 4rem;
    .about_inner{
        .heading{
            padding: 3rem 0rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            flex-direction: column;
            h4{
                color: #4F5660;
            }
        }
    }
}
.heading{
    color: $primary;
    &.text-center{
        max-width: 45rem;
        margin: 0 auto;
        h2{
            margin-bottom: 1rem;
        }
    }
    p{
        color: $grey;
    }
    &.linecross{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after{
            content: '';
            width: 100%;
            height: 1px;
            top: 50%;
            background: $dark;
            position: absolute;
            z-index: -1;
        }
        h2{
            padding: 0rem 2rem;
            background: #F9F9F9;
        }
    }
}
.communities{
    padding: 5rem 0rem;
    .heading{
        margin-bottom: 5rem;
    }
    .communities_inner{
        .communities_grid{
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 1rem;
            .community_card{
                overflow: hidden;
                border-radius: 0.35rem;
                position: relative;
                z-index: 1;
                min-height: 20rem;
                padding: 2rem;
                display: flex;
                align-items: flex-end;
                color: #FFFFFF;
                cursor: pointer;
                &.col2{
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
                &.fw{
                    grid-column-start: 1;
                    grid-column-end: 4;
                }
                img{
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    height: 120%;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                }
                &::after{
                    content: '';
                    width: 100%;
                    height: 80%;
                    z-index: -1;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 42.01%);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}
.values{
    margin-bottom: 5rem;
    .heading{
        margin-bottom: 3rem;
    }
    .values_grid{
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1rem;
        margin-bottom: 3rem;
        .value_card{
            padding: 2rem;
            background: $white;
            border-radius: 0.35rem;
            border: 1px solid rgba($color: $grey, $alpha: 0.1);
            .head{
                display: flex;
                gap: 1rem;
                align-items: center;
                margin-bottom: 1rem;
                h4{
                    color: $primary;
                }
            }
        }
    }
}
.gallery{
    margin-bottom: 5rem;
    .gallery_inner{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 1rem;
        .grid_box{
            .content{
                padding: 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 100%;
                h3{
                    margin-bottom: 1rem;
                    color: $primary;
                }
                .btn{
                    margin-top: 2rem;
                }
            }
            &.col2end{
                grid-column-start: 2;
                grid-column-end: 4;
            }
            &.col2start{
                grid-column-start: 1;
                grid-column-end: 3;
            }
            .image{
                overflow: hidden;
                width: 100%;
                height: 24rem;
                position: relative;
                border-radius: 0.35rem;
                img{
                    position: absolute;
                    width: 100%;
                    height: 135%;
                    object-fit: cover;
                }
            }
        }
    }
}
.slider_section{
    background: $white;
    padding: 3rem 0rem 5rem;
    .slide_wrapper{
        display: grid !important;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem;
        align-items: center;
        .content{
            grid-row-start: 2;
            h3{
                color: $primary;
                margin-bottom: 1rem;
            }
        }
        img{
            border-radius: 0.35rem;
        }
    }
}
.slick-dots {
    bottom: -3rem !important;
    li {
        transform: scale(0.7);
        transition-duration: 0.2s;
        &.slick-active{
            transform: scale(1);
        }
        button{
            &:before{
                background: $primary;
                border-radius: 10rem;
                color: transparent !important;
                transition-duration: 0.2s;
            }
        }
    }
}
.contact{
    margin: 3rem 0rem;
    .contact_inner{
        padding: 1.5rem;
        background: $white;
        border-radius: 0.35rem;
        border: 1px solid rgba($color: $grey, $alpha: 0.1);
        .form{
            display: grid;
            gap: 0.6rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            max-width: 40rem;
            margin: 0 auto;
            margin-top: 3rem;
            .fw{
                grid-column-start: 1;
                grid-column-end: 3;
            }
            .btn{
                margin-top: 1rem;
                grid-column-start: 2;
            }
        }
    }
}

.footer{
    .footer_inner{
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: 3rem 0rem;
        p, a{
            font-size: 0.8rem;
            color: $grey;
        }
        a{
            color: $dark;
        }
        .copyright{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            justify-content: flex-start;
            align-items: flex-start;
        }
        ul{
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .newsletter{
            display: flex;
            input{
                border-radius: 0.125rem 0rem 0rem 0.125rem;
            }
            .btn{
                border-radius: 0rem 0.125rem 0.125rem 0rem;
            }
        }
    }
}


// typography

body{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

h1{
    font-size: 4.5rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
}

h2{
    font-size: 3rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
}

h3{
    font-size: 2rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
}

h4{
    font-size: 1.5rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
}
.nav-link{
    font-size: 1rem;
}
p, a, .p{
    font-size: 1rem;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-decoration: none !important;
}
p{
    color: $grey;
    span{
        color: $primary;
    }
}


@media (max-width: 768px) {
    h1{
        font-size: 3.5rem;
    }
    h2 {
        font-size: 2.5rem;
    }
    h3{
        font-size: 2rem;
    }
    h4{
        font-size: 1.82688rem;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
    h5{
        font-size: 1.49938rem;
    }
}


// Properties Page Css

.property_section{
    .container-fluid{
        padding: 3rem;
        display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 20px;
    .content{
        max-width: 550px;
    }
        h3{
            color: $primary;
            line-height: 250%;
        }
        h6{
            color: #4F5660;
        }
        
    }
    .slider-img-container{
        display: flex !important;
        justify-content: center !important;
        height: 19rem !important;
        overflow: hidden !important;
        img{
            border-radius: 5px;
        }
    }
}

@media (max-width:500px){
    .property_section .container-fluid{
      padding: 1.5rem ;
      h3{
        line-height: 150% ;
    margin-bottom: 13px ;
      }
    }
}

@media (min-width: 576px){
.form-control{
    grid-column-start: auto ;
    grid-column-end: auto ;
}

.footer .footer_inner{
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

}

@media (min-width: 700px) {
    .values_grid{
            grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        }

        .slider_section .slide_wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .content{
                grid-row-start: 1;
            }
        }
}

@media (min-width: 950px) {
    .values_grid,.footer .footer_inner{
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }
}


/// Max Width css

@media (max-width:1100px){
    .gallery .gallery_inner {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        .grid_box{
            grid-column-start:auto !important;
            grid-column-end: auto !important;
           
        }
        }
}

@media (max-width:900px){
    .communities_inner .communities_grid{
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
        .community_card{
            grid-column-start: 3;
    grid-column-end: 5;
    &.fw {
        grid-column-start: 1;
        grid-column-end: 5 !important;
    }
        }
    }
}

@media (max-width:750px){
    .gallery .gallery_inner {
        grid-template-columns: repeat(1, minmax(0, 1fr));
     }

     .communities_inner .properties_grid{
        grid-template-columns: repeat(1, minmax(0, 1fr));
     }
}

@media (max-width: 600px){
.communities_inner .communities_grid {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    .community_card,.community_card.fw{
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
    }
}
}

@media (max-width:500px){
    p,h1,h2,h3,h4,h5,h6,.footer_inner .column_wrapper ul li{
        text-align: center;
        width: 100%;
    }

    .values .values_grid .value_card .head h4,.values .values_grid .value_card .body p{
        text-align: left !important;
    }

    .gallery .gallery_inner .grid_box .content{
        padding: 0;
    }

    .btn-container{
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .contact .contact_inner .form .btn{
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .footer .footer_inner .copyright{
        align-items: center;
    }
}

// Admin Css

.header_container{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
    padding: 20px;
}



.modal .modal-body{
        input,select{
            color: #666;
            font-family: 'Montserrat', sans-serif;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem; /* 150% */
            border: 1px solid $grey;
            border-radius: 5px;
            margin: 5px 0;
            outline: none;
            width: 100%;
            height: 40px;
            padding: 7px;
    }

    #community_img_uploader,#update_community_img_uploader{
        height: 0px;
    position: absolute;
    width: 0px;
    visibility: hidden;    
}

    .custom-file-upload{
        margin: 5px 0;
    width: 100%;
    border: 1px dashed;
    border-radius: 5px;
    text-align: center;
    padding: 7px;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    }


    .thumbnail-file-upload{
        margin: 5px 0;
    width: 100%;
    border: 1px dashed;
    border-radius: 5px;
    text-align: center;
    padding: 7px;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    margin-bottom: 15px;
    }

}

#modal-community-form ~ #modal-footer{
    display: none !important;
}

.dropdown-menu{
    padding: 0.2rem;
    border-radius: 0.325rem;
    .dropdown-item{
        font-size: 0.8rem;
        border-radius: 0.2rem;
        transition-duration: 0.2s;
        &:focus, &:hover{
            background: $primary;
            color: $white;
        }
    }
}
.properties_grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
    .grid_box{
        min-height: 20rem;
        background-size: cover;
        padding: 2.5rem;
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 0.35rem;
        h3{
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.25rem; /* 112.5% */
            color: $white !important;
        }
        p{
            color: $white;
        }
        &::after{
            content: '';
            width: 100%;
            height: 80%;
            z-index: -1;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 42.01%);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
//////////// Table Css


.table {

    overflow-x: scroll;
     min-width: 1120px;
    tr {
      border: none !important;
      height: 1.2rem;
    }
    td{
        height: 6rem ;
    }
  
    .entries {
      display: flex;
      justify-content: start;
      padding: 20px;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
    }

    .table-container{
        width: 100%;
        min-width: 900px;
    }
  
    input{
      color: #666;
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
      height: 1.8rem;
      border: 1px solid #D9D9D9;
      margin: 0 5px;
      outline: none;
  
      &:hover,
      &:active {
        outline: 2px solid $primary;
      }
    }
  
    .ent-inp{        
        width: 60px;
    }

  
    .users {
      width: 100%;
      min-width: 700px;
    }
  
    .user-h {
      color: #566A7F !important;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      border: none;
    }
  
    .user-t {
      color: #000 !important;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      border: none;
  
      img {
        border-radius: 5px;
        margin-right: 7px;
        width: 80px;
        height: 80px;
      }
    }
  
    .user-t,.user-h {
      text-align: center;
    }

    .user-row {
      height: 2.875rem;
      border: none !important;
    }
  
    .pagination-cont {
      width: 100%;
      border-top: 1px solid grey;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 10px 20px;
      flex-wrap: wrap;
      gap: 30px;
      .pagination{
        display: flex;
        align-items: center;
        gap: 7px;
      }
    }
  
    @media screen and (min-width: 550px) {
      .pagination-cont,
      .entries {
        justify-content: space-between;
      }
    }
  }

  .slick-dots li button:before,.slick-dots li {
    height: 10px;
    width: 10px;
  }

  #div-address p:nth-child(2){
    display: block !important;
  }

  